@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@font-face {
  font-family: GoogleFont;
  src: url(./fonts/Inter-VariableFont_slnt,wght.ttf);
}

html,
body {
  margin: 0;
  height: 100%;
  color: beige;
  background-color: black;
  font-family: GoogleFont;
  overflow-x: hidden;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

@keyframes moveLight {
    0% {
      background-position: 10% 10%;
    }
    10% {
      background-position: 60% 30%;
    }
    20% {
      background-position: 30% 80%;
    }
    30% {
      background-position: 80% 50%;
    }
    40% {
      background-position: 20% 70%;
    }
    50% {
      background-position: 50% 20%;
    }
    60% {
      background-position: 90% 40%;
    }
    70% {
      background-position: 40% 90%;
    }
    80% {
      background-position: 70% 60%;
    }
    90% {
      background-position: 20% 50%;
    }
    100% {
      background-position: 10% 10%;
    }
  }
  
  

b {
  font-weight: 800;
}

@media only screen and (max-width: 768px) {
  .Page {
    background: radial-gradient(circle at 50% 50%, #333333, #000000 25%);
    background-size: 200% 200%;
    background-attachment: fixed;
    animation: moveLight 20s ease infinite;
  }

  /* NAVBAR */

  .NavBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
    /* padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px; */

    white-space: nowrap;

    /* min-width: 768px; */
    height: 50px;

    background-color: transparent;
    color: beige;
    opacity: 0.6;

    border-radius: 0 0 20px 20px;

    position: -webkit-sticky;
    position: sticky;
    top: 0;

    transition: all 0.2s;

    z-index: 10;
  }

  .NavBar .pfp {
    display: none;
  }

  .LightMainContent .NavBar {
    background-color: beige;
  }

  .DarkMainContent .NavBar:hover {
    background-color: rgb(40, 40, 40);
    box-shadow: 0px 10px 5px 10px rgb(25, 25, 25);
    opacity: 0.95;
  }
  .LightMainContent .NavBar:hover {
    background-color: beige;
    /* box-shadow: 0px 5px 30px 10px rgb(100,100,100); */
    box-shadow: 0px 5px 10px 10px rgb(200, 200, 200);
    opacity: 0.95;
  }

  .NavBar img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 25px;
    margin-right: 15px;
  }

  .NavLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .NavLeft * {
    display: inline-block;
  }

  .NavBar h3 {
    margin-right: 0px;
    width: 120px;
  }

  .email {
    padding: 2px;
  }

  .NavBar a {
    /* padding: 2px; */
    /* margin-right: 20px;
        margin-left: 20px; */
    padding: 7px 5px 7px 5px;

    border-bottom: 1px solid transparent;
    border-radius: 5px;
    margin-right: 10px;

    transition: box-shadow 0.2s;
  }

  .NavBar a:link {
    text-decoration: none;
    color: inherit;
  }

  .NavBar a:hover {
    cursor: pointer;
    border-radius: 5px;
  }
  .DarkMainContent .NavBar a:hover {
    box-shadow: 0px 15px 8px -8px black !important; /*important overrides selected button shadow*/
    border-bottom: 1px solid black;
    /* border-bottom: 2px solid beige;
        border-left: 2px solid beige;
        border-right: 2px solid beige; */
    /* box-shadow: 0 0 10px 5px black !important;  important overrides selected button shadow */
  }
  .LightMainContent .NavBar .nav a:hover {
    box-shadow: 0 0 25px 5px rgb(150, 150, 150) !important;
  }
  .nav-selected {
    box-shadow: 0px 15px 8px -6px rgb(40, 40, 40) !important;
    border-bottom: 0.5px solid beige !important;
  }

  .ModeBtn {
    font-size: 20px;
    box-shadow: 0 0 50px 1px white;
    background-color: rgb(255, 255, 255, 0.15);
    border-radius: 250px;
  }
  .LightMainContent .ModeBtn {
    box-shadow: 0 0 40px 2px black;
    background-color: rgb(0, 0, 0, 0.2);
  }
  .DarkMainContent .ModeBtn:hover {
    box-shadow: 0 0 40px 25px whitesmoke !important;
    /* text-shadow: 0 0 40px yellow; */
    border-radius: 50%;
    background-color: rgb(255, 255, 255, 0.8);
  }
  .LightMainContent .ModeBtn:hover {
    box-shadow: 0 0 40px 25px black !important;
    border-radius: 50%;
    background-color: rgb(0, 0, 0, 0.8);
    text-shadow: 0 0 40px yellow;
  }

  .NavBar .blank {
    width: 40%;
  }

  .dropbtn {
    width: 60px;
    height: 40px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: rgb(50, 50, 50);
    color: beige;
    border: 1px ridge black;
  }
  .LightMainContent .dropbtn {
    background-color: beige;
    color: black;
  }

  .fa {
    margin-top: 2px;
  }

  .nav {
    min-width: 50px;
    margin-left: auto;
    list-style-type: none;
    display: inline-block;
    /* align-self: right; */
  }

  .nav-normal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .nav-dropdown {
    display: none;
  }

  #Expanded {
    /* display: flex;
        flex-direction: row;
        position: absolute;
        width: 300px;
        right: 1px;
        overflow: auto;
        max-height: 150px;
        background-color: black;
        z-index: 10; */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    /* padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px; */

    position: absolute;
    right: 1%;
    top: 100%;
    overflow: auto;
    z-index: 10;

    white-space: nowrap;

    width: 20%;
    min-width: fit-content;
    height: 240px;

    background-color: rgb(45, 45, 45);
    color: beige;
    opacity: 1;

    /* border-left: 2px solid beige;
        border-right: 2px solid beige; */
    border-radius: 20px;

    box-shadow: 0px 12px 5px 5px rgb(25, 25, 25);

    transition: box-shadow 0.3s;
  }
  .LightMainContent #Expanded {
    background-color: beige;
    color: black;
    border-top: 1px solid black;
    box-shadow: 0px 10px 15px 10px rgb(200, 200, 200);
    opacity: 0.95;
  }

  /* .NavDropdown:hover{
        display: inline-block;
        background-color: beige;
    } */

  /* .nav-project {
        position: relative;
    } */
  /* .nav-project:hover .projects-dropdown {
        display: flex;
    } */

  .projects-dropdown {
    position: absolute;
    top: 50px !important;
    right: 20% !important;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 160px;

    background-color: rgb(47, 47, 47);
    color: beige;

    /* border-top: 2px solid beige; */
    /* border-bottom: 2px solid beige; */
    border-radius: 20px;

    box-shadow: 0px 10px 5px 5px rgb(25, 25, 25);

    z-index: 10;
  }

  .projects-option {
    border-bottom: 1px solid transparent;
    margin: 15px 5px;
  }

  .projects-option:hover {
    box-shadow: 0px 15px 8px -8px black !important; /*important overrides selected button shadow*/
    border-bottom: 1px solid black;
    /* border-bottom: 2px solid beige; */
    /* border-right: 1px solid beige; */
  }

  .Front {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
  }

  /* PROFILE */

  .Profile {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    margin-top: 10%;
    width: 100%;
    height: 100%;
  }

  .NameInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 80%;

    /* border: 0px solid black; */
  }

  .Profile h1 {
    font-size: 50px;
    margin: 5px;
    text-align: center;
    /* text-indent: 20%; */
  }

  .NameInfo p {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .NameInfo ul {
    list-style: none;
    padding: 0;
    color: inherit;
  }
  .DarkMainContent .NameInfo ul {
    border-top: 2px solid beige;
    border-bottom: 2px solid beige;
  }
  .LightMainContent .NameInfo ul {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
  }

  .NameInfo li {
    display: inline-block;
    margin: 20px;
    margin-left: 40px;
    margin-right: 40px;
  }

  .NameInfo a:link,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  .NameInfo a:hover {
    color: rgb(125, 125, 125);
  }

  .NameInfo i {
    font-size: 30px;
  }

  .Profile .About {
    max-width: 50%;
    padding: 30px;
    margin-top: 10vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
  }

  .About p,
  .quick-link {
    color: beige;
    font-weight: 600;
    background-color: black;
    opacity: 80%;
    padding: 30px;

    border-radius: 10px;

    transition: all 0.3s;
  }
  .About p:hover,
  .quick-link:hover {
    background-color: rgb(50, 50, 50);
    box-shadow: 10px 10px 5px 10px rgb(25, 25, 25);
  }
  .quick-link {
    cursor: pointer;
    width: 80%;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.1em;
  }

  /* .shadow
    {
        display:block;
        position:relative;
    }

    .shadow:before
    {
        margin-top: 50px;
        margin-left: 80px;
        display:block;
        content:'';
        position:absolute;
        width: 43vh;
        height:60vh;
        box-shadow:inset 0px 0px 10px 10px black;
    } */

  .Profile .FormalPic {
    /* background: url(./images/formal_pic.jpg) left top no-repeat; */
    margin-left: 80px;
    margin-top: 50px;
    width: auto;
    height: 60vh;
    /* position: absolute; */
    left: 20px;
    /* filter: brightness(90%); */
    /* max-height: 500px; */

    border-radius: 10px;
    box-shadow: 5px 5px 10px 10px rgb(50, 50, 50);
  }
  .LightMainContent .Profile .FormalPic {
    box-shadow: 5px 5px 10px 10px rgb(150, 150, 150);
  }

  /* RESUME */

  .Resume {
    text-align: center;
    margin-top: 50px;
    width: 100%;
  }

  .Resume iframe {
    height: 100vh;
    width: 95%;
    min-width: 350px;
    border: 10px solid rgb(25, 25, 25);
    border-radius: 5px;
  }

  /* Experience */

  .ExperienceContainer {
    padding: 10px;
    width: 95% !important;
    min-width: fit-content;

    border: 2px solid transparent;

    transition: all 0.3s;
  }
  .ExperienceContainer:hover {
    background-color: rgb(50, 50, 50);
    box-shadow: 0px 0px 5px 20px rgb(25, 25, 25);
    border-radius: 10px;

    border-top: 2px solid beige;
    border-bottom: 2px solid beige;
  }

  .ExperienceHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .ExperienceHeader h1 {
    margin: 0px;
  }
  .ExperienceHeader h2 {
    margin: 10px 0;
  }
  .ExperienceHeader a:link,
  a:visited {
    color: beige;
  }

  .ExperienceInfo li {
    margin: 10px 5px;
  }

  /* Project */

  .Project {
    width: 100%;
  }

  .Project i {
    color: beige;
  }

  .ProjectContainer {
    padding: 10px;
    width: 95%;
    min-width: 300px;

    border: 2px solid transparent;

    transition: all 0.3s;
  }
  .ProjectContainer:hover {
    background-color: rgb(50, 50, 50);
    box-shadow: 0px 0px 5px 20px rgb(25, 25, 25);
    border-radius: 10px;

    border-top: 2px solid beige;
    border-bottom: 2px solid beige;
  }
  .DarkMainContent .ProjectContainer {
    /* border-bottom: 2px solid beige; */
  }
  .LightMainContent .ProjectContainer {
    border-bottom: 2px solid black;
  }

  .ProjectContainer h1 a:link,
  a:visited {
    color: beige;
  }
  .LightMainContent .ProjectContainer h1 a:link,
  a:visited {
    color: black;
  }

  .LightMainContent .ProjectContainer i {
    color: black;
  }

  .ProjectInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    width: 95%;
  }

  .bullets {
    width: 90%;
    margin: 0;
  }

  .ProjectInfo li {
    margin: 10px 5px;
  }
  .ProjectInfo li a:link,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  .ProjectInfo img {
    margin: 20px 10px;
    height: 30%;
    /* max-width: 500px; */
    width: 90%;
    flex: 0 0 auto;

    box-shadow: 0 0 25px 20px rgb(25, 25, 25);
    border: 2px solid black;
    transition: 0.25s;
  }
  /* .ProjectInfo img:hover {
        margin-left: 0px;
        height: 500px;
        max-width: 800px;
        transition-duration: 0.5s;
    } */
  .LightMainContent .ProjectInfo img {
    box-shadow: 0 0 25px 25px rgb(175, 175, 175);
    border: 2px solid white;
  }

  .ProjectInfo .ThisOne {
    margin-left: 50px;
    width: 90%;
    height: 30%;
    min-width: 0;
    flex: 0 0 auto;
    background-color: rgb(75, 75, 75);
    border-radius: 20px;
    text-align: center;
    color: beige;

    box-shadow: 0 0 25px 25px rgb(25, 25, 25);
  }
  .LightMainContent .ProjectInfo .ThisOne {
    background-color: rgb(175, 175, 175);
    box-shadow: 0 0 25px 25px rgb(175, 175, 175);
    color: black;
  }

  /* Education */

  .Education {
    width: 100%;
  }

  .EducationContainer {
    padding: 10px;
    width: 95%;
    min-width: 300px;

    border: 2px solid transparent;

    transition: all 0.3s;
  }
  .EducationContainer:hover {
    background-color: rgb(50, 50, 50);
    box-shadow: 0px 0px 5px 20px rgb(25, 25, 25);
    border-radius: 10px;

    border-top: 2px solid beige;
    border-bottom: 2px solid beige;
  }
  .LightMainContent .EducationContainer {
    border-bottom: 2px solid black;
  }

  .EducationContainer h1 a:link {
    color: beige;
  }
  .LightMainContent .EducationContainer h1 a:link {
    color: black;
  }
  .EducationContainer h1 a:visited {
    color: beige;
  }
  .LightMainContent .EducationContainer h1 a:visited {
    color: black;
  }

  .EducationContainer h4,
  h5 {
    margin: 0;
  }

  .EducationInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .EducationInfo .sub {
    margin-left: 20px;
  }

  .EducationInfo li {
    margin: 10px 5px;
  }

  .EducationInfo img {
    margin-left: 50px;
  }

  .UwLogo {
    height: 250px;
    width: 250px;
    margin-right: 20px;
    box-shadow: 0 0 5px 10px rgb(50, 50, 50);
    border-radius: 125px;
  }

  .RhsLogo {
    height: 250px;
    width: 300px;
  }

  /* SKILLS */

  .Skills {
    align-items: center;
    text-align: center;
    padding: 30px 50px;
    width: 100%;
  }

  .Skills h1 {
    margin-top: 50px;
  }

  .SkillSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 95%;
  }

  .SkillItem {
    margin: 10px;
    display: grid;
    grid-template-columns: 20px 40px 20px;
    grid-template-rows: 150px 50px 10px 20px 20px;
    background-color: inherit;
    max-width: 800px;
  }
  .SkillItem a:link, a:visited {
    color: inherit;
    text-decoration: none;
  }
  /* .SkillItem:hover {
        position: relative;
        grid-template-columns: 0 160px 0;
        grid-template-rows: 200px 0 10px 20px 20px;
        
    } */
  /* .SkillItem:hover .tooltip {
        visibility: visible;
    } */

  .Bar {
    background-color: beige;
    grid-row: 1/3;
    grid-column: 2;
    box-shadow: 0 0 20px 20px rgb(50, 50, 50);
    border: 1px solid black;

    position: relative;
    width: 40px;
    left: 0;
    /* left: -80px; */

    cursor: default;

    transition: all 0.3s;

    overflow: hidden;
  }
  /* .BarExpanded {
        position: relative;
        left: -80px;
        width: 200px;
        
        background-color: rgb(245,245,220, 0.3);
        cursor: default;
        border: 1px solid black;

        transition: width 2s;
    } */
  .LightMainContent .Bar {
    background-color: rgb(34, 34, 34);
    /* box-shadow: 0 0 20px 15px rgb(200,200,200); */
    box-shadow: 0 0 20px 15px beige;
  }

  .Bar .info {
    position: absolute;
    top: 0;
    text-align: left;

    width: 180px;

    cursor: default;
    margin: 10px;

    opacity: 0;
  }
  .Bar .info .skillInfoHeader {
    border-bottom: 2px solid white;
  }
  /* .Bar:hover .info {
        opacity: 1;
    } */

  .Fill {
    /* position: absolute;
        bottom:0; */
    background-color: rgb(25, 25, 25);
    /* height: 100%; */
    z-index: 100;
  }
  .LightMainContent .Fill {
    background-color: rgb(225, 225, 225);
  }

  .Space {
    border-top: 2px solid beige;
    z-index: 5;
    grid-row: 4;
    grid-column: 1/4;
  }
  .LightMainContent .Space {
    border-top: 2px solid black;
  }

  .Name {
    grid-row: 5;
    grid-column: 2;
    grid-column: 1/4;
    text-align: center;
  }

  .tooltip {
    position: relative;
    top: 0;

    display: inline-block;
    border-bottom: 1px dotted black;
    background-color: beige;
    width: 160px;
    z-index: 5;

    visibility: hidden;
  }

  /* FOOTER */

  .Footer {
    margin-top: 100px;
    height: 10vh;
    padding: 20px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .Page {
    background: radial-gradient(circle at 50% 50%, #333333, #000000 25%);
    background-size: 200% 200%;
    background-attachment: fixed;
    animation: moveLight 20s ease infinite;
  }

  /* NAVBAR */

  .NavBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    /* padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px; */

    white-space: nowrap;

    /* min-width: 768px; */
    height: 50px;

    background-color: transparent;
    color: beige;
    opacity: 0.6;

    border-radius: 0 0 20px 20px;

    position: -webkit-sticky;
    position: sticky;
    top: 0;

    transition: all 0.2s;

    z-index: 10;
  }

  .LightMainContent .NavBar {
    background-color: beige;
  }

  .DarkMainContent .NavBar:hover {
    background-color: rgb(40, 40, 40);
    box-shadow: 0px 10px 5px 10px rgb(25, 25, 25);
    opacity: 0.95;
  }
  .LightMainContent .NavBar:hover {
    background-color: beige;
    /* box-shadow: 0px 5px 30px 10px rgb(100,100,100); */
    box-shadow: 0px 5px 10px 10px rgb(200, 200, 200);
    opacity: 0.95;
  }

  .NavBar img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 25px;
    margin-right: 15px;
  }

  .NavLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .NavLeft * {
    display: inline-block;
  }

  .NavBar h3 {
    margin-right: 20px;
  }

  .email {
    padding: 2px;
  }

  .NavBar a {
    /* padding: 2px; */
    margin-right: 20px;
    margin-left: 20px;
    padding: 7px 5px 7px 5px;

    border-bottom: 1px solid transparent;
    border-radius: 5px;

    transition: box-shadow 0.2s;
  }

  .NavBar a:link {
    text-decoration: none;
    color: inherit;
  }

  .NavBar a:hover {
    cursor: pointer;
    border-radius: 5px;
  }
  .DarkMainContent .NavBar a:hover {
    box-shadow: 0px 15px 8px -8px black !important; /*important overrides selected button shadow*/
    border-bottom: 1px solid black;
    /* border-bottom: 2px solid beige;
        border-left: 2px solid beige;
        border-right: 2px solid beige; */
    /* box-shadow: 0 0 10px 5px black !important;  important overrides selected button shadow */
  }
  .LightMainContent .NavBar .nav a:hover {
    box-shadow: 0 0 25px 5px rgb(150, 150, 150) !important;
  }
  .nav-selected {
    box-shadow: 0px 15px 8px -6px rgb(40, 40, 40) !important;
    border-bottom: 0.5px solid beige !important;
  }

  .ModeBtn {
    font-size: 20px;
    box-shadow: 0 0 50px 1px white;
    background-color: rgb(255, 255, 255, 0.15);
    border-radius: 250px;
  }
  .LightMainContent .ModeBtn {
    box-shadow: 0 0 40px 2px black;
    background-color: rgb(0, 0, 0, 0.2);
  }
  .DarkMainContent .ModeBtn:hover {
    box-shadow: 0 0 40px 25px whitesmoke !important;
    /* text-shadow: 0 0 40px yellow; */
    border-radius: 50%;
    background-color: rgb(255, 255, 255, 0.8);
  }
  .LightMainContent .ModeBtn:hover {
    box-shadow: 0 0 40px 25px black !important;
    border-radius: 50%;
    background-color: rgb(0, 0, 0, 0.8);
    text-shadow: 0 0 40px yellow;
  }

  .NavBar .blank {
    width: 40%;
  }

  .dropbtn {
    width: 60px;
    height: 40px;
    margin-right: 10px;

    border-radius: 10px;
    background-color: rgb(50, 50, 50);
    color: beige;
    border: 1px ridge black;
  }
  .LightMainContent .dropbtn {
    background-color: beige;
    color: black;
  }

  .fa {
    margin-top: 2px;
  }

  .nav {
    min-width: 50px;
    margin-left: auto;
    list-style-type: none;
    display: inline-block;
    /* align-self: right; */
  }

  .nav-normal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .nav-dropdown {
    display: none;
  }

  #Expanded {
    /* display: flex;
        flex-direction: row;
        position: absolute;
        width: 300px;
        right: 1px;
        overflow: auto;
        max-height: 150px;
        background-color: black;
        z-index: 10; */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;

    position: absolute;
    right: 1%;
    top: 100%;
    overflow: auto;
    z-index: 10;

    white-space: nowrap;

    width: 500px;
    height: 50px;

    background-color: rgb(45, 45, 45);
    color: beige;
    opacity: 1;

    /* border-left: 2px solid beige;
        border-right: 2px solid beige; */
    border-radius: 20px;

    box-shadow: 0px 12px 5px 5px rgb(25, 25, 25);

    transition: box-shadow 0.3s;
  }
  .LightMainContent #Expanded {
    background-color: beige;
    color: black;
    border-top: 1px solid black;
    box-shadow: 0px 10px 15px 10px rgb(200, 200, 200);
    opacity: 0.95;
  }

  /* .NavDropdown:hover{
        display: inline-block;
        background-color: beige;
    } */

  /* .nav-project {
        position: relative;
    } */
  /* .nav-project:hover .projects-dropdown {
        display: flex;
    } */

  .projects-dropdown {
    position: absolute;
    top: 50px;
    right: 0px !important;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 160px;

    background-color: rgb(47, 47, 47);
    color: beige;

    /* border-top: 2px solid beige; */
    /* border-bottom: 2px solid beige; */
    border-radius: 20px;

    box-shadow: 0px 10px 5px 5px rgb(25, 25, 25);

    z-index: 10;
  }

  .projects-option {
    border-bottom: 1px solid transparent;
    margin: 15px 5px;
  }

  .projects-option:hover {
    box-shadow: 0px 15px 8px -8px black !important; /*important overrides selected button shadow*/
    border-bottom: 1px solid black;
    /* border-bottom: 2px solid beige; */
    /* border-right: 1px solid beige; */
  }

  .Front {
    min-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 50px;

    /* background: linear-gradient(270deg, #000000, #1a1a1a, #262626, #000000);
        background-size: 200% 200%; 
        animation: gradientShift 20s ease infinite;  */

    /* background: radial-gradient(circle at 50%50%, #4a4a4a, #000000 30%);
        background-size: 120% 120%; 
        animation: moveLight 15s ease infinite;  */
  }

  /* PROFILE */

  .Profile {
    min-height: 100vh;
    padding: 30px;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-width: 700px;
  }

  .NameInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15vh;
    width: 50vw;
    min-width: 300px;
    max-width: 50%;

    /* border: 0px solid black; */
  }

  .Profile h1 {
    font-size: 50px;
    margin: 5px;
    text-align: center;
    /* text-indent: 20%; */
  }

  .NameInfo p {
    text-align: center;
    width: 60%;
    display: flex;
    justify-content: center;
  }

  .NameInfo ul {
    list-style: none;
    padding: 0;
    color: inherit;
  }
  .DarkMainContent .NameInfo ul {
    border-top: 2px solid beige;
    border-bottom: 2px solid beige;
  }
  .LightMainContent .NameInfo ul {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
  }

  .NameInfo li {
    display: inline-block;
    margin: 20px;
    margin-left: 40px;
    margin-right: 40px;
  }

  .NameInfo a:link,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  .NameInfo a:hover {
    color: rgb(125, 125, 125);
  }

  .NameInfo i {
    font-size: 30px;
  }

  .Profile .About {
    max-width: 50%;
    padding: 30px;
    margin-top: 10vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
  }

  .About p,
  .quick-link {
    color: beige;
    font-weight: 600;
    background-color: black;
    opacity: 80%;
    padding: 30px;

    border-radius: 10px;

    transition: all 0.3s;
  }
  .About p:hover,
  .quick-link:hover {
    background-color: rgb(50, 50, 50);
    box-shadow: 10px 10px 5px 10px rgb(25, 25, 25);
  }
  .quick-link {
    cursor: pointer;
    width: 80%;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.1em;
  }

  /* .shadow
    {
        display:block;
        position:relative;
    }

    .shadow:before
    {
        margin-top: 50px;
        margin-left: 80px;
        display:block;
        content:'';
        position:absolute;
        width: 43vh;
        height:60vh;
        box-shadow:inset 0px 0px 10px 10px black;
    } */

  .Profile .FormalPic {
    /* background: url(./images/formal_pic.jpg) left top no-repeat; */
    margin-left: 80px;
    margin-top: 50px;
    width: auto;
    height: 60vh;
    /* position: absolute; */
    left: 20px;
    /* filter: brightness(90%); */
    /* max-height: 500px; */

    border-radius: 10px;
    box-shadow: 5px 5px 10px 10px rgb(50, 50, 50);
  }
  .LightMainContent .Profile .FormalPic {
    box-shadow: 5px 5px 10px 10px rgb(150, 150, 150);
  }

  /* RESUME */

  .Resume {
    text-align: center;
    margin-top: 50px;
  }

  .Resume iframe {
    height: 100vh;
    width: 80vw;
    border: 10px solid rgb(25, 25, 25);
    border-radius: 5px;
    min-width: 700px;
  }

  /* Experience */

  .ExperienceContainer {
    padding: 20px;
    min-width: fit-content;

    border: 2px solid transparent;

    transition: all 0.3s;
  }
  .ExperienceContainer:hover {
    background-color: rgb(40, 40, 40);
    box-shadow: 0px 0px 5px 20px rgb(25, 25, 25);
    border-radius: 10px;

    border-top: 2px solid beige;
    border-bottom: 2px solid beige;
  }

  .ExperienceHeader {
    display: flex;
    flex-direction: horizontal;
    justify-content: flex-start;
    align-items: center;
  }
  .ExperienceHeader a:link,
  a:visited {
    color: beige;
  }

  .ExperienceInfo li {
    margin: 10px 5px;
  }

  /* Project */

  .Project i {
    color: beige;
  }

  .ProjectContainer {
    padding: 20px;
    min-width: 600px;

    border: 2px solid transparent;

    transition: all 0.3s;
  }
  .ProjectContainer:hover {
    background-color: rgb(40, 40, 40);
    box-shadow: 0px 0px 5px 20px rgb(25, 25, 25);
    border-radius: 10px;

    border-top: 2px solid beige;
    border-bottom: 2px solid beige;
  }
  .DarkMainContent .ProjectContainer {
    /* border-bottom: 2px solid beige; */
  }
  .LightMainContent .ProjectContainer {
    border-bottom: 2px solid black;
  }

  .ProjectContainer h1 a:link,
  a:visited {
    color: beige;
  }
  .LightMainContent .ProjectContainer h1 a:link,
  a:visited {
    color: black;
  }

  .LightMainContent .ProjectContainer i {
    color: black;
  }

  .bullets {
    width: 50%;
    margin: 0;
  }

  .ProjectInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .ProjectInfo ul {
    margin-top: 0;
    min-width: 300px;
  }

  .ProjectInfo li {
    margin: 10px 5px;
  }
  .ProjectInfo li a:link,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  .ProjectInfo img {
    margin-left: 50px;
    height: 300px;
    max-width: 500px;
    min-width: 0;
    flex: 0 0 auto;

    box-shadow: 0 0 25px 20px rgb(25, 25, 25);
    border: 2px solid black;
    transition: 0.25s;
  }
  /* .ProjectInfo img:hover {
        margin-left: 0px;
        height: 500px;
        max-width: 800px;
        transition-duration: 0.5s;
    } */
  .LightMainContent .ProjectInfo img {
    box-shadow: 0 0 25px 25px rgb(175, 175, 175);
    border: 2px solid white;
  }

  .ProjectInfo .ThisOne {
    margin-left: 50px;
    width: 550px;
    height: 250px;
    min-width: 0;
    flex: 0 0 auto;
    background-color: rgb(75, 75, 75);
    border-radius: 20px;
    text-align: center;
    color: beige;

    box-shadow: 0 0 25px 25px rgb(25, 25, 25);
  }
  .LightMainContent .ProjectInfo .ThisOne {
    background-color: rgb(175, 175, 175);
    box-shadow: 0 0 25px 25px rgb(175, 175, 175);
    color: black;
  }

  /* Education */

  .EducationContainer {
    padding: 20px;
    min-width: 800px;

    border: 2px solid transparent;

    transition: all 0.3s;
  }
  .EducationContainer:hover {
    background-color: rgb(40, 40, 40);
    box-shadow: 0px 0px 5px 20px rgb(25, 25, 25);
    border-radius: 10px;

    border-top: 2px solid beige;
    border-bottom: 2px solid beige;
  }
  .LightMainContent .EducationContainer {
    border-bottom: 2px solid black;
  }

  .EducationContainer h1 a:link {
    color: beige;
  }
  .LightMainContent .EducationContainer h1 a:link {
    color: black;
  }
  .EducationContainer h1 a:visited {
    color: beige;
  }
  .LightMainContent .EducationContainer h1 a:visited {
    color: black;
  }

  .EducationContainer h4,
  h5 {
    margin: 0;
  }

  .EducationInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .EducationInfo .sub {
    margin-left: 20px;
  }

  .EducationInfo li {
    margin: 10px 5px;
  }

  .EducationInfo img {
    margin-left: 50px;
  }

  .UwLogo {
    height: 250px;
    width: 250px;
    margin-right: 20px;
    box-shadow: 0 0 5px 10px rgb(50, 50, 50);
    border-radius: 125px;
  }

  .RhsLogo {
    height: 250px;
    width: 300px;
  }

  /* SKILLS */

  .Skills {
    align-items: center;
    text-align: center;
    padding: 30px 50px;
  }

  .Skills h1 {
    margin-top: 50px;
  }

  .SkillSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .SkillItem {
    margin: 30px 50px;
    display: grid;
    grid-template-columns: 20px 40px 20px;
    grid-template-rows: 150px 50px 10px 20px 20px;
    background-color: inherit;
    max-width: 800px;
  }
  .SkillItem a:link, a:visited {
    color: inherit;
  }
  /* .SkillItem:hover {
        position: relative;
        grid-template-columns: 0 160px 0;
        grid-template-rows: 200px 0 10px 20px 20px;
        
    } */
  /* .SkillItem:hover .tooltip {
        visibility: visible;
    } */

  .Bar {
    background-color: beige;
    grid-row: 1/3;
    grid-column: 2;
    box-shadow: 0 0 20px 20px rgb(50, 50, 50);
    border: 1px solid black;

    position: relative;
    width: 40px;
    left: 0;
    /* left: -80px; */

    cursor: default;

    transition: all 0.3s;

    overflow: hidden;
  }
  .Bar:hover {
    width: 200px !important;
    left: -80px;

    background-color: rgb(245, 245, 220, 0.3);
  }
  /* .BarExpanded {
        position: relative;
        left: -80px;
        width: 200px;
        
        background-color: rgb(245,245,220, 0.3);
        cursor: default;
        border: 1px solid black;

        transition: width 2s;
    } */
  .LightMainContent .Bar {
    background-color: rgb(34, 34, 34);
    /* box-shadow: 0 0 20px 15px rgb(200,200,200); */
    box-shadow: 0 0 20px 15px beige;
  }

  .Bar .info {
    position: absolute;
    top: 0;
    text-align: left;

    width: 180px;

    cursor: default;
    margin: 10px;

    opacity: 0;

    transition: all 0.3s;
  }
  .Bar .info .skillInfoHeader {
    border-bottom: 2px solid white;
  }
  .Bar:hover .info {
    opacity: 1;
  }

  .Fill {
    /* position: absolute;
        bottom:0; */
    background-color: rgb(25, 25, 25);
    /* height: 100%; */
    z-index: 100;
  }
  .LightMainContent .Fill {
    background-color: rgb(225, 225, 225);
  }

  .Space {
    border-top: 2px solid beige;
    z-index: 5;
    grid-row: 4;
    grid-column: 1/4;
  }
  .LightMainContent .Space {
    border-top: 2px solid black;
  }

  .Name {
    grid-row: 5;
    grid-column: 2;
    grid-column: 1/4;
    text-align: center;
  }

  .tooltip {
    position: relative;
    top: 0;

    display: inline-block;
    border-bottom: 1px dotted black;
    background-color: beige;
    width: 160px;
    z-index: 5;

    visibility: hidden;
  }

  /* FOOTER */

  .Footer {
    margin-top: 100px;
    height: 10vh;
    padding: 20px;
    text-align: center;
  }
}
